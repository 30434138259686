import { endpoints } from 'src/api/constants';
import { RootState } from 'src/reducers/rootReducer';
import 'src/app/App/App.prototype';
import { ClientViewConfigurationData } from 'src/components/glide-view/glide-view.model';
import { activeTabSelector } from 'src/reducers/tabs';
import { sanitizeGridData } from 'src/mappers/orders/sanitize-order-data';
import { View } from 'src/api/queries/display-view';

export interface ClientView {
  data: any;
  schema: any;
  viewUri: string;
  clientViewConfiguration: ClientViewConfigurationData;
  webLayouts: {
    date: string;
    data: { name: string; owner?: string; json_layout: any };
    viewUri: string;
    uri: string;
  }[];
  layoutManager?: boolean;
}
export type ClientViewType = {
  configUri: string;
  [key: string]: any;
};

export const getClientView = ({ configUri, ...props }: ClientViewType) => {
  const configName = configUri;
  return {
    url: endpoints.clientViews.root,
    body: {
      uri: configUri,
      ...props,
    },
    transform: (body: any) => {
      const { data, schema, web_layouts, view_uri, client_view_configuration } = body;
      const webLayouts = web_layouts.length > 0 ? JSON.parse(web_layouts) : web_layouts;
      // console.log(`update ${configName} for view`, body);
      const parsedConfigData = JSON.parse(client_view_configuration);
      const clientViewConfiguration: ClientViewConfigurationData = {
        ...parsedConfigData.data,
        uri: parsedConfigData.uri,
      };
      return {
        views: {
          [`${configName}`]: {
            data: sanitizeGridData(JSON.parse(data)),
            schema,
            viewUri: view_uri,
            clientViewConfiguration,
            webLayouts,
            collapseWrapperOpen: undefined,
            layoutManager: undefined,
          },
        },
      };
    },
    options: { method: 'GET' },
    queryKey: configName,
    force: true,
    update: {
      views: (prev: any, next: any) => ({
        ...prev,
        [`${configName}`]: {
          inspectorData: prev?.[`${configName}`]?.inspectorData,
          ...next[`${configName}`],
        },
      }),
    },
  };
};

export const selectClientViewData = (state: RootState, configUri?: string): View => {
  const activeViewUri = configUri || activeTabSelector(state);
  return state.entities.views?.[activeViewUri];
};

export const selectGidDataToUpdate = (state: RootState, configUri?: string): { key: string; data: object } => {
  const activeViewUri = configUri || activeTabSelector(state);
  return state.entities.views?.[activeViewUri]?.GridDataToUpdate;
};

export const selectBottomGidDataToUpdate = (state: RootState): { key: string; data: object } => {
  return state.entities.views?.orderBottomPanelGrid?.bottomGidDataToUpdate;
};

export const isPending = (state: RootState, configUri: string) =>
  state.queries[configUri] && state.queries[configUri].isPending;
