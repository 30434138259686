import React, { useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { RootState } from 'src/reducers';
import { Changes } from 'src/components/glide-object-manager/model';
import {
  EditableTabKeys,
  primaryActionConfig,
} from 'src/components/glide-object-manager/components/glide-object-manager.model';
import { glideQuerySelectorViewName, isPendingQuerySelector } from 'src/api/query';
import { endpoints } from 'src/api/constants';
import { dispatchActions } from 'src/app/store';
import { actionTypes } from 'redux-query';
import GlideObjectManagerComponent, {
  GlideObjectManagerComponentProps,
} from 'src/components/glide-object-manager/components/glide-object-manager';

export type GlideObjectManagerProps = Omit<
  GlideObjectManagerComponentProps,
  'dataSource' | 'gridData' | 'onRefresh'
> & { onBack: () => void; objectCollectionUri: string };

type GridDataType = { object_uri: string; object_field_name: string; object_form_props: any; selected_row_data: any };

export const GlideObjectManager = React.memo(
  ({ onGridViewChangesSaved, selectedRowData, bottomDataGrid, ...props }: GlideObjectManagerProps) => {
    const [objectCollectionUri, setObjectCollectionUri] = useState(props.objectCollectionUri);
    const [fieldName, setFieldName] = useState(props.fieldName);
    const [previousGridsData, setPreviousGridsData] = useState<Array<GridDataType>>([]);
    const [formProps, setFormProps] = useState(props.formProps);
    const [rowDataSelected, setRowDataSelected] = useState(selectedRowData);
    const dataGrid = useSelector((state: RootState) =>
      glideQuerySelectorViewName(state, `gom_${props.objectCollectionUri}_${props.fieldName}`),
    );
    const isPending: boolean = useSelector((state: RootState) =>
      isPendingQuerySelector(state, `gom_${props.objectCollectionUri}_${props.fieldName}`),
    );
    const isdeletePending: boolean = useSelector((state: RootState) =>
      isPendingQuerySelector(state, 'deleteObjectInCollection'),
    );
    const getDataGridDetails = (body: any) => {
      dispatchActions.db.fetch({
        endpoint: endpoints.getCollection.rootObject,
        body: { object_uri: body.object_uri, object_field_name: body.object_field_name },
        options: {
          method: 'GET',
        },
        queryKey: `gom_${props.objectCollectionUri}_${props.fieldName}`,
        // force: true,
      });
    };
    useEffect(() => {
      getDataGridDetails({ object_uri: objectCollectionUri, object_field_name: fieldName });
      dispatchActions.components.update('global', { isGomOpen: true });
    }, []);

    const handleGetDataGrid = useCallback(
      () => getDataGridDetails({ object_uri: objectCollectionUri, object_field_name: fieldName }),
      [fieldName, objectCollectionUri],
    );

    const handleGetNestedDataGrid = useCallback(
      ({ object_uri, object_field_name, object_form_props, selected_row_data }: GridDataType) => {
        setPreviousGridsData(prevGridsData => [
          ...prevGridsData,
          ...[
            {
              object_uri: objectCollectionUri,
              object_field_name: fieldName,
              object_form_props: formProps,
              selected_row_data: rowDataSelected,
            },
          ],
        ]);
        getDataGridDetails({ object_uri, object_field_name });
        setFieldName(object_field_name);
        setObjectCollectionUri(object_uri);
        setFormProps(object_form_props);
        setRowDataSelected(selected_row_data);
      },
      [fieldName, objectCollectionUri, previousGridsData, formProps, rowDataSelected],
    );

    const handleNestedOnBack = useCallback(() => {
      const nextPreviousGridsData = [...previousGridsData];
      const previousGrid = nextPreviousGridsData.pop();
      if (!previousGrid) return props.onBack();
      setFieldName(previousGrid.object_field_name);
      setObjectCollectionUri(previousGrid.object_uri);
      setPreviousGridsData(nextPreviousGridsData);
      getDataGridDetails({ object_uri: previousGrid.object_uri, object_field_name: previousGrid.object_field_name });
      setFormProps(previousGrid.object_form_props);
      setRowDataSelected(previousGrid.selected_row_data);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previousGridsData, props.onBack]);

    const handleOnGridViewChangesSaved = useCallback(
      ({ changedRows, newRows, deleteRows, currentAmountOfItems, clientViewUri }: Changes) => {
        const hasChanges = Object.keys(changedRows).length > 0;
        const objectUpdating = fieldName
          .toLowerCase()
          .split('_')
          .map(s => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ');
        if (deleteRows?.length > 0) {
          dispatchActions.db.update({
            endpoint: endpoints.delete.rootObject,
            body: { object_uri: objectCollectionUri, delete_content: { fund_allocations: deleteRows } },
            // ⚠️ Pen test Issue Vulnerability ID 1000136466 - Potentially harmful HTTP methods enabled
            options: { method: 'POST', headers: { 'X-Http-Method-Override': 'DELETE' } },
            queryKey: 'deleteObjectInCollection',
            meta: {
              object_field_name: fieldName,
              notification: {
                [actionTypes.MUTATE_START]: `Saving ${objectUpdating}`,
                [actionTypes.MUTATE_SUCCESS]: `${objectUpdating} updated`,
              },
            },
          });
        }
        if (hasChanges || newRows.length > 0) {
          dispatchActions.db.update({
            endpoint: endpoints.update.rootObject,
            body: {
              object_uri: objectCollectionUri,
              [fieldName]: changedRows,
              new_objects: newRows,
            },
            queryKey: `gom_${props.objectCollectionUri}_${props.fieldName}`,
            options: {
              method: 'POST',
              // ⚠️ Pen test Issue Vulnerability ID 1000136466 - Potentially harmful HTTP methods enabled
              headers: { 'X-Http-Method-Override': 'PUT' },
            },
            meta: {
              object_field_name: fieldName,
              changedRows,
              newRows,
              notification: {
                [actionTypes.MUTATE_START]: `Saving ${objectUpdating}`,
                [actionTypes.MUTATE_SUCCESS]: `${objectUpdating} updated`,
              },
            },
            transform: (body: any) => {
              const transformedBody = {
                [clientViewUri]: {
                  GridDataToUpdate: {
                    key: objectCollectionUri,
                    data: { [objectUpdating]: JSON.parse(body.data) },
                  },
                },
              };
              if (bottomDataGrid && bottomDataGrid?.displayView?.uri?.split('/').pop() === fieldName) {
                return {
                  views: {
                    ...transformedBody,
                    bottomGidDataToUpdate: JSON.parse(body.data),
                  },
                };
              }
              return {
                views: transformedBody,
              };
            },
            update: {
              views: (prev: any, next: any) => {
                const data = {
                  ...prev,
                  [clientViewUri]: {
                    ...prev[clientViewUri],
                    GridDataToUpdate: next[clientViewUri].GridDataToUpdate,
                  },
                };
                if (bottomDataGrid && bottomDataGrid?.displayView?.uri?.split('/').pop() === fieldName) {
                  const recivedchangedRows = next.bottomGidDataToUpdate
                    .filter((item: any) => Object.keys(changedRows[item._uri]).length > 0)
                    .map((record: any) => {
                      const finalRecord: any = {};
                      for (const key in record) {
                        if (Object.keys(changedRows[record._uri]).includes(key) || key === '_uri') {
                          finalRecord[key] = record[key];
                        }
                      }
                      return finalRecord;
                    });

                  // const recordToUpdate = Object.keys(changedRows).map()

                  return {
                    ...data,
                    // orderBottomPanelGrid: {
                    //   ...prev.orderBottomPanelGrid,
                    //   data: [...next.orderBottomPanelGrid.data],
                    // },
                    orderBottomPanelGrid: {
                      ...prev.orderBottomPanelGrid,
                      bottomGidDataToUpdate: recivedchangedRows,
                    },
                  };
                }
                return data;
              },
            },
          });
        }

        if (onGridViewChangesSaved) {
          // Notify parent
          onGridViewChangesSaved({ changedRows, newRows, currentAmountOfItems, clientViewUri });
        }
      },
      [fieldName, objectCollectionUri, onGridViewChangesSaved],
    );
    return (
      <GlideObjectManagerComponent
        {...props}
        onRefresh={handleGetDataGrid}
        fieldName={fieldName}
        formProps={formProps}
        dataSource={{
          data: dataGrid?.data,
          schema: dataGrid?.schema,
          fieldRules: dataGrid?.fieldRules,
        }}
        overridePopupGOMProps={{
          onBack: handleNestedOnBack,
          getDataGrid: handleGetNestedDataGrid,
        }}
        displayViewData={{ ...dataGrid, displayView: { data: dataGrid?.displayViewData } }}
        selectedRowData={rowDataSelected}
        onGridViewChangesSaved={handleOnGridViewChangesSaved}
        editObjectIcons={primaryActionConfig[fieldName as EditableTabKeys]}
        removeIconsConfig={['revertButton']}
        loading={isPending || isdeletePending}
        objectCollectionUri={objectCollectionUri}
      />
    );
  },
);

// export default GlideObjectManager;
const mapStateToProps = (state: RootState) => ({
  bottomDataGrid: glideQuerySelectorViewName(state, 'orderBottomPanelGrid'),
});

export default connect(mapStateToProps)(GlideObjectManager);
