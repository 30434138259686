import React from 'react';
import * as S from './bottom-panel.style';
import { RootState } from 'src/reducers';
import { connect } from 'react-redux';
import { dispatchActions } from 'src/app/store';
import { selectComponents } from 'src/reducers/components';
import { activeTabSelector } from 'src/reducers/tabs';

interface BottomPanelProps {
  children: any;
  clientViewUri: string;
  displayRowSelectMsg?: boolean;
  rowSelectMsg?: string;
}

interface ReduxProps {
  readonly isExpanded?: boolean;
}

interface ReduxDispatch {
  setExpandedState: (isExpanded: boolean, clientViewUri: string) => void;
}

const BottomPanel = ({
  children,
  setExpandedState,
  displayRowSelectMsg,
  rowSelectMsg,
  clientViewUri,
  isExpanded,
}: BottomPanelProps & ReduxProps & ReduxDispatch) => {
  const toggleCollapse = () => setExpandedState(!isExpanded, clientViewUri);

  return (
    <S.BottomPanelWrapper $isExpanded={(isExpanded as boolean) ?? false}>
      <S.ChevronDownButton
        data-testid="bottom-panel-button"
        onClick={toggleCollapse}
        style={{ zIndex: 1, cursor: 'pointer' }}
      >
        {isExpanded ? <S.ArrowDown fontSize="medium" /> : <S.ArrowUp fontSize="medium" />}
      </S.ChevronDownButton>
      {displayRowSelectMsg ? (
        <S.RowSelectMessage>{rowSelectMsg ? rowSelectMsg : 'Please select a row'}</S.RowSelectMessage>
      ) : (
        children
      )}
    </S.BottomPanelWrapper>
  );
};

const mapStateToProps = (state: RootState): ReduxProps => {
  const clientViewUri = activeTabSelector(state);
  const components = selectComponents(state);
  return {
    isExpanded: components?.viewComponents?.[clientViewUri as string]?.['bottomPanelExpandedState']?.['isExpanded'],
  };
};

const mapDispatchToProps = (_dispatch: any): ReduxDispatch => ({
  setExpandedState: (isExpanded: boolean, clientViewUri: string) =>
    dispatchActions.components.updateView('bottomPanelExpandedState', clientViewUri, { isExpanded }),
});

export default connect(mapStateToProps, mapDispatchToProps)(BottomPanel);
