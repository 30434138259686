import React, { useCallback, useState } from 'react';
import GlideObjectManager from 'src/components/glide-object-manager/container/glide-object-manager.container';
import { Dialog } from 'src/components/glide-object-manager/style';
import { CustomCellRenderKeyProps, DataSource } from '@virtus/components/DxDataGrid/DxDataGrid';
import { CellData } from 'src/components/grids/dxgrid-client-view/dxgrid-client-view.model';
import { getFormPropsForGlideObjectManager } from 'src/components/forms/glide-data-content/glide-data-content';
import { getObjectCollectionColumns } from 'src/components/glide-object-manager/utils/get-object-collection-cells';
import { GlideDataContentProps } from 'src/components/forms/glide-data-content';
import { dispatchActions } from 'src/app/store';
import { CONFIRMATION_DIALOG, backDropClick } from 'src/utils/constants';
import { useConfirmationDialog } from '@virtus/components/withConfirmationDialogOnClick/withConfirmationDialogOnClick';
import GlideObjectCommentaryContainer from '../container/glide-object-commentary-container';

type ObjectManagerPropsType = {
  fieldName: string;
  objectCollectionUri: string;
  formProps: { [key: string]: any };
  editing: boolean;
  selectedRowData: { [key: string]: any };
};

interface UseGlideObjectManagerProps {
  schema: DataSource['schema'];
  objectEditActions?: GlideDataContentProps['objectEditActions'];
  objectEditInspectors?: GlideDataContentProps['objectEditInspectors'];
  gridActions: {
    refresh?: () => void;
  };
  id?: string;
}

const defaultObjectManagerProps: ObjectManagerPropsType = {
  fieldName: '',
  objectCollectionUri: '',
  formProps: {},
  editing: false,
  selectedRowData: {},
};

const defaultCommentarytManagerProps: any = {
  commentaryUris: [],
  creditUri: '',
  componentType: '',
  instanceUri: '',
};

export const useGlideObjectManager = ({
  schema,
  objectEditActions,
  objectEditInspectors,
  gridActions,
  id,
}: UseGlideObjectManagerProps) => {
  const [showObjectManager, setShowObjectManager] = useState(false);
  const [objectManagerProps, setObjectManagerProps] = useState(defaultObjectManagerProps);
  const [displayType, setDisplayType] = useState<string>('preview');
  const [commentaryManagerProps, setCommentaryManagerProps] = useState(defaultCommentarytManagerProps);
  const hideGrid = id === 'data-grid-credits';

  const closeGOMDialog = () => {
    setShowObjectManager(false);
    if (hideGrid) {
      setCommentaryManagerProps(defaultCommentarytManagerProps);
    } else {
      setObjectManagerProps(defaultObjectManagerProps);
      dispatchActions.components.update('global', { isGomOpen: false });
    }
    setDisplayType('preview');
  };

  const { DialogComponent: QuitGOM, onDispatcherClick: displayConfirmationDialog } = useConfirmationDialog({
    onClick: closeGOMDialog,
    ...CONFIRMATION_DIALOG,
  });

  /**
   * Mapping correct uri name when clicking on hyperlink displayed on datagrid
   */
  const getFieldName = (displayName: string) =>
    schema.find((field: any) => field.display_name === displayName)?.field_name;

  const onShowObjectManager = (cellData: CellData) => {
    const objectCollectionUri = (cellData.data as any)._uri;
    const field = cellData.column.caption;
    const fieldName = getFieldName(cellData.column.caption);
    const formProps = getFormPropsForGlideObjectManager({ field, formValues: cellData.data });
    const editing = !cellData.value && true;
    setShowObjectManager(true);
    if (hideGrid) {
      const creditUri = (cellData.data as any)['Credit Detail'];
      const commentaryUris = (cellData as any).value ? [...(cellData as any).value] : [];
      const instanceUri = (cellData as any).data?._uri;
      setCommentaryManagerProps({
        commentaryUris,
        creditUri,
        instanceUri,
        componentType: field,
        fromColumn: true,
      });
    } else {
      setObjectManagerProps({
        fieldName,
        objectCollectionUri,
        formProps,
        editing,
        selectedRowData: cellData.data,
      });
    }
  };

  const objectCollectionColumns: CustomCellRenderKeyProps = getObjectCollectionColumns(schema, onShowObjectManager);

  const onCloseObjectManager = useCallback((_event?: any, reason?: any) => {
    if (reason === backDropClick) return;
    //@ts-ignore
    if (window['gridInstances']['GOM'].current?.instance?.getController('editing')?.getChanges(true).length > 0) {
      displayConfirmationDialog();
    } else {
      // @ts-ignore
      window['gridInstances']['GOM'].current?.instance?.clearFilter();
      closeGOMDialog();
    }
  }, []);

  const onExpandObjectManager = useCallback(() => setDisplayType('fullscreen'), []);

  const onGridViewChangesSaved = useCallback(() => {
    /**keeping this if we need to refresh grid */
    //if (gridActions.refresh) gridActions.refresh();
    //onCloseObjectManager();
  }, [gridActions, onCloseObjectManager]);

  const GlideObjectManagerOverlay = (
    <>
      <QuitGOM />
      <Dialog
        open={showObjectManager}
        onClose={onCloseObjectManager}
        display_type={displayType}
        disableEnforceFocus={true}
        disableEscapeKeyDown
      >
        {hideGrid ? (
          <GlideObjectCommentaryContainer
            {...commentaryManagerProps}
            onBack={onCloseObjectManager}
            handleExpansion={onExpandObjectManager}
          />
        ) : (
          <GlideObjectManager
            onBack={onCloseObjectManager}
            displayType={displayType}
            handleExpansion={onExpandObjectManager}
            actions={objectEditActions}
            inspectors={objectEditInspectors}
            onGridViewChangesSaved={onGridViewChangesSaved}
            {...objectManagerProps}
          />
        )}
      </Dialog>
    </>
  );

  return { objectCollectionColumns, GlideObjectManagerOverlay };
};
