import { GlideObject, GlideObjectSummary } from 'src/models/glide/glideObject';
import { RouteComponentProps } from '@reach/router';
import { Components } from 'src/reducers/components';
import { GlideAction } from 'src/reducers/actions';
import { View } from 'src/api/queries/display-view';

export type ClientViewKeys =
  | ClientViewTypeLookUps.Grid
  | ClientViewTypeLookUps.Pivot
  | ClientViewTypeLookUps.Dashboard
  | ClientViewTypeLookUps.Custom;

export enum ClientViewTypeLookUps {
  Grid = 'Grid',
  Pivot = 'Pivot',
  Report = 'Report',
  Combined = 'Combined',
  Custom = 'Custom',
  Dashboard = 'Dashboard',
}
export interface ClientViewConfigurationData {
  enable_static_row_action: boolean;
  selectedLayout: any;
  uri: string; // ie: instance/client_view_configuration/allocation_optimisations
  behaviour: string; // ie: instance/behaviours/module
  client_region: 'BottomRegion' | 'MainRegion'; // ie: lookups/mainregion
  client_view_type: ClientViewKeys; // ie "lookups/pivot";
  display_name: string; // ie "Allocation Optimisations";
  enabled: boolean; // true
  expand_links: boolean;
  filter_uri: string; //ie "selection/type/allocation_optimisations?expanded=true"
  view: string; // "instance/display_views/allocation_optimisations_view",
  object_type: string; // "object_types/allocation_optimisations"
  is_time_series?: boolean;
  static_menu_actions?: GlideAction[]; // Additional grid actions (expanded on app load by CVC search)
  is_instance_sensitive?: boolean;
  category?: string; // ie: "Blotters"
  actions_collection?: GlideAction[]; // expanded app load
  actions_collection_web?: GlideAction[]; // expanded on app load
  // Browser region is a web only prop that takes precedence on the client region
  browser_region?: 'BottomRegion' | 'MainRegion'; // Override the client_region
  web_summary_panel?: any;
  toolbar_buttons?: string[]; // grid toolbar buttons
  bottom_panel_static_fields?: Partial<GlideObject>[];
  bottom_panel_details?: any;
  bottom_panel_hypo_test_result_details?: boolean;
  instance_sensitive_field_name?: string;
  is_default_view?: boolean;
  has_baseline_date?: boolean;
  permissions?: [];
  default_dates?: string;
  is_caching_enabled?: boolean;
  is_pivot_total_button_disabled?: boolean;
  is_test_results?: boolean;
  show_row_totals?: boolean;
  show_column_totals?: boolean;
  default_date_filter?: string;
  pivot_default_view_definition?: string;
}

export interface ClientViewConfiguration extends GlideObject {
  data: ClientViewConfigurationData;
}

export type ViewComponent = {
  [key in ClientViewKeys]: JSX.Element;
};

export interface GlideViewType {
  category: string;
  clientViewUri: string;
}

interface ActionParam {
  clientViewUri: string;
  isManualRefresh?: boolean;
}

export interface GlideViewReduxDispatch {
  fetchClientView: ({ clientViewUri, isManualRefresh }: ActionParam) => void;
}

export interface GlideViewReduxProps {
  clientViewData: View;
  clientViewConfiguration: ClientViewConfigurationData;
  components: Components;
  isPending: boolean;
  orderBottomPanel: GlideObjectSummary[];
  hypoTestResultDetails: any;
  bottomPanelResults: any;
  isLoadingBottomPanelResults: boolean;
  instanceSensitiveBottomPanel: ClientViewConfiguration[];
  currentInspectorForm: any;
  fundURIs: [];
}

export interface GlideViewReduxDispatch {}

export type GlideViewProps = GlideViewType & GlideViewReduxProps & RouteComponentProps & GlideViewReduxDispatch;
