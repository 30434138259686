import {
  Column,
  Editing,
  Scrolling,
  ColumnChooser,
  ColumnFixing,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  SearchPanel,
  SortByGroupSummaryInfo,
  Sorting,
  Export,
  Paging,
  Selection,
  Search,
} from 'devextreme-react/data-grid';
import { DataSourceType, mapSchema } from '@virtus/components/DxDataGrid/utils/mapSchema';
import { getExtraProps, DxColumn, createExportObject } from '@virtus/components/DxDataGrid/DxDataGrid';
import React, { useCallback, useMemo } from 'react';
import { GridViewProps, DefaultGridViewConfig } from './model';
import * as S from './style';
import { RenderLookup } from '@virtus/components/DxDataGrid/utils/edit/renderLookup';
import { getOnToolbarPreparing } from '@virtus/components/DxDataGrid/toolbarManagement';
import { DEFAULT_TOOLBAR_ICONS_ORDER } from 'src/utils/constants';
import { Template } from 'devextreme-react/core/template';
import { onColumnGrouped } from 'src/utils/common';
import { getSummaryGlide } from '@virtus/components/DxDataGrid/utils/mapSchemaGlide';
import { GridActionColumn } from 'src/components/grids/grid-action-column/grid-action-column';
import { exportDataGrid as _exportExcel } from 'devextreme/excel_exporter';
// @ts-ignore //if removed, the file-saver gives error to install dev dependencies but after its installed there comes error for starting the application.
import { saveAs } from 'file-saver';
import dxDataGrid from 'devextreme/ui/data_grid';

type ExportExcelParams = {
  component: dxDataGrid;
  selectedRowsOnly?: boolean;
  cancel?: boolean;
};

import GlideInspectorActions from 'src/components/inspectors/glide-inspector/glide-inspector-actions';
const getColumns = ({
  dxColumns,
  EditObjectCellComponent,
}: {
  dxColumns: DxColumn[];
  EditObjectCellComponent: any;
}) => {
  const extraProps = {
    allowResizing: true,
    columnFixing: { enabled: true },
    allowSorting: true,
    editorOptions: { showClearButton: true },
  };

  return dxColumns.map((column: any) => {
    const props = { ...extraProps, ...column, ...getExtraProps(column) };
    return (
      <Column
        {...props}
        visible={column.visible !== undefined ? column.visible : true}
        {...(column.dataType === 'object' &&
          !column.lookups &&
          EditObjectCellComponent && {
            editCellComponent: _props => <EditObjectCellComponent props={_props} column={column} />,
          })}
      >
        {column.lookups && column.dataType === 'object' && RenderLookup(column.lookups.values)}
      </Column>
    );
  });
};

const defaultToolbarButtons = {
  add: [],
  remove: [],
  addAfter: [],
  addBefore: [],
};

const defaultGomProps: DefaultGridViewConfig = {
  edit: false,
  allowDeleting: true,
  usePagination: true,
  skipkeyId: false,
  skipSchema: false,
};

export const GridView = React.memo(
  React.forwardRef(
    (
      {
        dataGrid,
        style,
        customCellRenderKeyProps,
        onCustomToolBarPreparing,
        onRefresh,
        customizeColumns,
        EditObjectCellComponent,
        dxDataGridProps,
        customDxToolbarButtonsActions,
        layout,
        showRefreshButton = true,
        actionsCollection,
        allowHeaderSearch = false,
        exportFileName,
        gridColumnAction,
        scrollingProps,
        exportSheetName = 'Main Sheet',
        showFilterButton = true,
        isSearchPanelLeftAligned = false,
        isAuditEnabled = true,
        repaintChangesOnly = false,
        ...props
      }: GridViewProps,
      ref: any,
    ) => {
      const totalItemSummary = useMemo(() => dataGrid?.summaryRender && dataGrid?.summaryRender(), [
        dataGrid?.summaryRender,
      ]);

      const options = useMemo(() => {
        return props?.overrideGridViewProps
          ? { ...defaultGomProps, ...props.overrideGridViewProps }
          : { ...defaultGomProps };
      }, [props?.overrideGridViewProps]);

      const dxColumns = useMemo(() => mapSchema(dataGrid?.dataSource, DataSourceType.GLIDE, customCellRenderKeyProps), [
        customCellRenderKeyProps,
        dataGrid?.dataSource,
      ]);

      /**
       * EditDecimalCellComponent has big performance hits that makes it unusable
       * (1) User has to double click on certain values like 0 or when cell is in valid state
       * (2) Focus is not persisted through out the editing mode
       * (3) We can't see clear all (X) button
       * (4) First edit is very slow
       * (5) Required validation and other regex pattern doesn't work and many more...
       */
      // ℹ️ This memoization is needed to avoid an error in DXGrid when edit multiple cells and
      // close the modal. It memoizes columns on initial render.
      const columns = useMemo(
        () =>
          getColumns({
            dxColumns,
            EditObjectCellComponent,
            // EditDecimalCellComponent: DecimalWithMultiplier,
          }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dxColumns?.length],
      );
      const onToolbarPreparing = useMemo(() => {
        return onCustomToolBarPreparing
          ? onCustomToolBarPreparing()
          : getOnToolbarPreparing({
              toolbarButtons: dxDataGridProps?.toolbarButtons || defaultToolbarButtons,
              showFilterButton: showFilterButton,
              showRefresh: showRefreshButton,
              onRefresh,
              hideFilterPanel: false,
              hideHeaderFilter: true,
              excludeAlignment: [],
              isFilterButtonLeftAligned: true,
              toolbarIconsOrder: props?.toolbarIconsOrder || DEFAULT_TOOLBAR_ICONS_ORDER,
              customDxToolbarButtonsActions,
              isSearchPanelLeftAligned: isSearchPanelLeftAligned,
              isAuditEnabled: isAuditEnabled,
            });
      }, [onCustomToolBarPreparing, dxDataGridProps?.toolbarButtons, options.edit]);

      const summary = useMemo(
        () =>
          getSummaryGlide(dataGrid?.dataSource?.schema, {
            summaryDataTypeProps: {
              totalItemSummary: totalItemSummary,
            },
          }),
        [dataGrid?.dataSource?.schema, totalItemSummary],
      );

      const customExport = useCallback(
        async (e: ExportExcelParams) => {
          const excelModule = await import('exceljs');
          const workbook = new excelModule.Workbook();
          const worksheet = workbook.addWorksheet(exportSheetName);
          _exportExcel(createExportObject(e, worksheet, dataGrid?.dataSource)).finally(async () => {
            // write the file
            const buffer: any = await workbook.xlsx.writeBuffer();
            const fileName = `${exportFileName}.xlsx`;
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
          });
          e.cancel = true;
        },
        [dataGrid?.dataSource],
      );

      return (
        <S.GridWrapper style={style?.wrapper} edit={options.edit} layout={layout}>
          <S.DataGrid
            {...props}
            id="table-modal-datagrid"
            ref={ref}
            dataSource={dataGrid?.dataSource.data}
            onOptionChanged={dxDataGridProps?.onOptionChanged ?? onColumnGrouped}
            keyExpr={options.skipkeyId ? '' : '_uri'}
            showColumnLines={true}
            showRowLines={true}
            rowAlternationEnabled={true}
            hoverStateEnabled={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            cellHintEnabled={true}
            columnAutoWidth={true}
            columnHidingEnabled={false}
            onToolbarPreparing={onToolbarPreparing}
            repaintChangesOnly={repaintChangesOnly}
            customizeColumns={customizeColumns}
            onExporting={customExport}
            {...dxDataGridProps}
          >
            <Scrolling mode={options.usePagination ? 'standard' : 'infinite'} {...scrollingProps} />
            {summary}
            {gridColumnAction && GridActionColumn({ actions: gridColumnAction })}
            {columns}
            <Editing
              mode={options.editMode ? options.editMode : 'batch'}
              allowUpdating={options.edit && options.allowUpdating}
              allowDeleting={options.edit && options.allowDeleting}
              allowAdding={options.edit && options.allowAdding}
            />
            <Sorting mode="multiple" />
            <Paging enabled={options.usePagination} defaultPageSize={20} />
            <Export enabled={true} allowExportSelectedData={true} />
            <Selection mode="single" />
            <HeaderFilter visible>
              <Search enabled={allowHeaderSearch} />
            </HeaderFilter>
            <FilterRow />
            <FilterPanel />
            <GroupPanel visible emptyPanelText="Create Grouping" />
            <SearchPanel visible />
            <ColumnFixing enabled={true} />
            <Grouping autoExpandAll={true} contextMenuEnabled={true} />
            <SortByGroupSummaryInfo summaryItem="count" />
            <ColumnChooser height={300} enabled={true} mode="select" />
            {!options.edit && actionsCollection && (
              <Template
                key="actions"
                name="otherActionsTemplate"
                render={() => (
                  <GlideInspectorActions actions={actionsCollection} actionButtonText="More" layout={layout} />
                )}
              />
            )}
          </S.DataGrid>
        </S.GridWrapper>
      );
    },
  ),
);
